.hero-sect {
    /* min-height: 100vh; */
    background: #000;
    background: #121212;
    /* background: linear-gradient(rgba(0, 0, 0,.3), rgba(0, 0, 0, .3)), url('../images/homepage/summer.jpg'); */
    /* padding: 5% 1%; */
    min-height: 80vh;
    background-position: center;
    background-size: cover;
    padding: 5% 1.5%;
    overflow: hidden;
    /* padding-bottom: 30px; */
}

/* .hero-sect .grid-2 {
    align-items: center;
} */

.hero-sect .hero-img-block {
    width: 60%;
    height: 40rem;
    background: #888B86;
    border-radius: 0px 0px 50% 50%;
    margin: 0px auto;
    display: block;
}

.hero-sect h2 {
    background: #ED2047;
    padding: 2px 6px;
    border: 2px solid #fff;
    color: #fff;
    width: 100%;
    font-size: 3.5rem;
    width: max-content;
}

.hero-sect p {
    font-size: 1.8rem;
    color: #fff;
}

.real-homepage.homepage-content .hero-section video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.hero-sect .first-hero-section {
    min-height: 65vh;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 2fr 1.2fr;
}

.hero-sect .second-hero-sect {
    background: green;
    background: linear-gradient(to right, #A9A1AA, #AE919B);
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.hero-sect .second-hero-sect img {
    width: 100%;
    width: auto;
    height: 50rem;
    /* left: -100px; */
    /* position: absolute;
    bottom: 0px; */
    /* object-fit: cover;
    object-position: center; */
}

.hero-sect .second-hero-sect .second-hero-bottom-tag {
    position: absolute;
    background: #121212;
    border: 1px solid #121212;
    outline: none;
    bottom: 0px;
    right: 0px;
    height: 100px;
    width: 120px;
    /* border-radius: 4px; */
}

.homepage-display .about-sub-props .grid-2 h3 {
    width: 100%;
}

/* The newly created pseudo element */
/* .hero-sect .second-hero-sect .second-hero-bottom-tag::before {
    content: "";
    position: absolute;
    left: 0px;
    background-color: red;
    bottom: -10px;
    height: 50px;
    width: 25px;
    border-radius: 0px 0px 25px 0px;
    z-index: 1000;
    box-shadow: 0 -25px 0 0 red;
} */

.hero-sect .second-hero-sect .first-hero-top-tag {
    width: 120px;
    height: 100px;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #000;
    border-radius: 0px 0px 0px 16px;
}

.hero-sect .second-hero-sect .second-hero-bottom-tag .third-hero-bottom-tag {
    position: absolute;
    top: -30px;
    width: 40px;
    height: 40px;
    background: #121212;
    border: 1px solid #121212;
    outline: none;
    border-radius: 8px 8px 0px 0px;
}

.hero-sect .first-hero-section button {
    background: #0F0F0F;
    font-size: 1.4rem;
    color: #fff;
    padding: 15px 35px;
    border: 1px solid #0F0F0F;
    border-radius: 50px;
}

.hero-sect .colored-text-bg {
    background: green;
    background: linear-gradient(to right, #A9A1AA, #AE919B);
    border-radius: 16px;
    height: 15rem;
}

.hero-sect .sec-hero-sect {
    height: 15rem;
    background: #fff;
    border-radius: 16px;
}

.hero-sect .second-hero-section {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 2fr 1.2fr;
    margin-top: 30px;
}

.hero-colored-bg {
    background: #FBFAF8;
    padding: 5% 0px;
}

.sec-hero {
    background: #121212;
}

.sec-hero img:nth-child(2),
.sec-hero img:nth-child(3) {
    margin-top: 45%;
}

.sec-hero img {
    border-radius: 4px;
}

.sec-hero p {
    font-size: 1.5rem;
    color: rgba(253, 253, 253, .6);
    line-height: 1.8;
}

.sec-hero h2 {
    font-size: 3.5rem;
    color: #fff;
}

.sec-hero .sec-hero-text {
    width: 60%;
    margin: 0px auto;
    display: block;
    text-align: center;
    margin-bottom: 80px;
}

/* dancerapy-props */
.homepage {
    /* background: #121212; */
}

.dancerapy-props {
    background: #121212;
}

.dancerapy-props h4 {
    border-top: 2px solid #fff;
    padding-top: 20px;
    clear: both;
    color: #D9B061;
    font-size: 1.8rem;
}

.dancerapy-props h2 {
    color: #D9B061;
    font-size: 3.5rem;
    width: 40%;
}

.dancerapy-props h5 {
    float: right;
    color: #fff;
    text-transform: uppercase;
}

.dancerapy-props p {
    font-size: 1.4rem;
    color: #fff;
    line-height: 1.8;
}

.company-logos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-logos .company-logos-list {
    display: flex;
    grid-gap: 20px;
}

.sec-testimonial-block .sec-testimonial-showcase {
    border: 1px solid #E6DFD0;
    padding: 25px 0px;
}

.sec-testimonial-block .sec-testimonial-showcase p {
    width: 60%;
    margin: 0px auto;
    display: block;
    font-size: 2rem;
    text-align: center;
}

.sec-testimonial-block .sec-testimonial-showcase p.sec-testimonial-author {
    font-size: 1.4rem;
    text-align: center;
}

.dance-hero-props .grid-2 p {
    font-size: 1.5rem;
    line-height: 1.8;
}

.dance-hero-props .grid-2 h3 {
    text-transform: uppercase;
    /* font-size: 1.5rem;
    line-height: 1.8; */
}

.dance-hero-props .internal-grid-flex {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 5fr;
}

.dance-hero-props .internal-grid-flex img {
    width: 100%;
    height: auto;
}

.dance-hero-props h2 {
    font-size: 3.5rem;
    line-height: 1.3;
    text-transform: uppercase;
    width: 60%;
}

.dance-props-sect .ash.desktop-only img {
    width: 60%;
    margin: 0px auto;
    display: block;
}

.dance-hero-props h2 span {
    font-size: 3.5rem;
    /* line-height: 1.8; */
    font-family: 'Gilroy Bold';
}

/* white-sect */
.white-sect {
    height: 10rem;
    background: #fff;
}

/* dance-props */
.dance-props {
    border-radius: 40px 40px 0px 0px;
    padding: 5% 0px;
    padding-top: 0%;
    padding-bottom: 0px;
}

.dance-props p {
    line-height: 1.8;
}

.dance-props .grid-flex {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin-bottom: 10px;
    align-items: end;
}

.dance-props .props-link-tab {
    background: #2F2F2F;
    border-radius: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.dance-props .props-link-tab._2 {
    background: #601FEB;
}

.dance-props .props-link-tab p {
    font-size: 1.4rem;
    color: rgba(253, 253, 253, .6);
    margin-bottom: 20px;
    line-height: 1.8;
}

.props-link-tab-header .grid-flex {
    display: flex;
    justify-content: space-between;
}

.dance-props .props-link-tab svg {
    fill: #fff;
    /* width: 100px; */
    height: 25px;
    /* float: right; */
}

.dance-props .props-link-tab-header h3 {
    margin-bottom: 0px;
    font-size: 2rem;
    color: #B8E900;
}

.dance-props .props-link-tab._2 h3 {
    color: #fff;
}

.dance-props .props-link-tab-body img {
    width: 100%;
    height: 20rem;
    border-radius: 16px;
    object-fit: cover;
    object-position: center top;
}

.dance-props .props-link-tab-image {
    position: relative;
}

.dance-props .props-link-tab-image .shadow {
    position: absolute;
    top: 0px;
    background: rgba(0, 0, 0, .1);
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.dance-props .grid-flex p {
    /* color: #fff; */
    font-size: 1.5rem;
}

.dance-props .grid-flex h2 {
    /* color: #fff; */
    font-size: 2.5rem;
}

.dance-tagline {
    position: relative;
    background: #000;
}

.dance-tagline .dance-tagline-content {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 160px;
    padding-top: 120px;
}

.dance-tagline .circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #fff;
}

.dance-tagline .circle._1 {
    position: absolute;
    right: 150px;
    top: -50px;
}

.dance-tagline .circle._2 {
    position: absolute;
    left: 150px;
    bottom: -50px;
}

.dance-tagline .dance-tagline-content .center-me {
    align-items: center;
}

.dance-tagline .dance-tagline-content h1 {
    font-size: 12rem;
    color: #fff;
}

.dance-tagline .dance-tagline-content p {
    font-size: 2rem;
    color: #fff;
}

/* dance-links */
.dance-links h2,
.dance-props .grid-flex h2 {
    font-size: 4rem;
    font-size: 3.5rem;
    text-transform: uppercase;
}

.dance-links h2 span.color-1,
.dance-props .grid-flex h2 span.color-1 {
    color: #56A6F6;
    font-family: 'Gilroy Bold';
}

.dance-links h2 span.color-2,
.dance-props .grid-flex h2 span.color-2 {
    color: #FF9154;
    font-family: 'Gilroy Bold';
}

.dance-links p {
    font-size: 1.5rem;
    line-height: 1.7;
    margin-bottom: 5px;
}

.dance-links .grid-2 {
    /* display: grid;
    grid-template-columns: 2.5fr 1fr;
    margin-bottom: 10px;
    align-items: end; */
    display: flex;
    /* display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin-bottom: 10px;
    align-items: end; */
}

.dance-links h3 {
    font-size: 5rem;
    /* color: #fff; */
    margin-bottom: 20px;
}

.dance-links .dance-center-text {
    text-align: center;
    font-size: 2rem;
}

.dance-links.sec-dance-links .dance-video-flex .bg-button {
    background: #F5F5F5;
    border-radius: 4px;
    padding: 10px 15px;
}

.dance-links.sec-dance-links .dance-video-flex .bg-button h3 {
    font-size: 1.8rem;
    margin-bottom: 0px;
}

.dance-links.sec-dance-links .dance-video-flex {
    /* align-items: center; */
    display: flex;
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    grid-gap: 20px;
}

.dance-links.sec-dance-links .dance-video-flex button {
    margin: 0px auto;
    display: block;
}

.dance-links .dance-links-block {
    background: #F5F5F5;
    padding: 60px 20px;
    border-radius: 16px;
    padding-top: 80px;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;
}

.dance-links .dance-links-block ion-icon {
    position: absolute;
    right: -60px;
    top: -40px;
    /* height: 300rem; */
    font-size: 15rem;
    color: #E9E9E9;
    /* width: 100rem; */
}

.dance-links .dance-links-block.active ion-icon {
    color: #FF8E50;
}

.dance-links .dance-links-block.active {
    background: #2F2F2F;
}

.dance-links .dance-links-block.active p,
.dance-links .dance-links-block.active h3 {
    color: #fff;
}

.dance-links .dance-links-block p {
    /* color: #fff; */
}

.dance-links .video-cover {
    height: 40rem;
    background: #0F0F0F;
    border-radius: 16px;
    /* width: 60%; */
}

.dance-links .rolling-card {
    background: #0F0F0F;
    padding: 15px 25px;
    display: flex;
    border-radius: 50px;
    width: max-content;
    margin-bottom: 10px;
    float: right;
}

.dance-links.sec-dance-links .rolling-card ion-icon {
    color: #fff;
    font-size: 3rem;
}

.dance-links.sec-dance-links .rolling-card ion-icon.colored {
    color: #FF9154;
}

.dance-links.sec-dance-links .bg-button {
    margin-bottom: 10px;
}

.dance-links.sec-dance-links .bg-button button {
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    padding: 15px 35px;
    font-size: 1.3rem;
    border-radius: 50px;
}

.dance-links.sec-dance-links .bg-button.active button {
    background: #0F0F0F;
    border: 1px solid #0F0F0F;
    color: #fff;
}

.dance-links.sec-dance-links .grid-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

/* testimonial-data */
.testimonial-data {
    background: #2F2F2F;
    padding: 5% 0px;
}

.testimonial-data h1 {
    font-size: 7rem;
    font-size: 5.5rem;
    color: #fff;
    text-transform: uppercase;
}

.testimonial .testimonial-author-sect {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    margin-top: 30px;
}

.testimonial .testimonial-author-sect p {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 10px;
}

.testimonial .testimonial-author-sect h3 {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 5px;
    word-spacing: 1px;
    /* font-family: 'Gilroy Regular'; */
    /* font-weight: normal; */
}

.testimonial .testimonial-author-sect .avatar {
    background: red;
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.instructor-display .testimonial .testimonial-author-sect .avatar {
    height: 90px;
    width: 90px;
}

.instructor-display .testimonial .testimonial-author-sect p {
    margin-bottom: 0px;
}

.testimonial ion-icon {
    color: #fff;
    font-size: 3rem;
    padding-right: 10px;
}

.dance-video-flex {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

@media screen and (max-width:720px) {
    .hero-sect .first-hero-section {
        display: flex;
        flex-direction: column;
    }

    .hero-sect .white-text-bg {
        height: 50vh;
    }

    .hero-sect .second-hero-sect {
        height: 30vh;
    }

    .dance-links .grid-flex,
    .dance-links.sec-dance-links .dance-video-flex,
    .dance-props .grid-flex,
    .dance-props .grid-3 {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    .dance-links .grid-3 {
        display: flex;
        flex-direction: column;
    }

    .testimonial-data h1 {
        font-size: 3rem;
    }

    .dance-props .props-link-tab-header .grid-flex {
        flex-direction: row;
    }

    .dance-links h2,
    .dance-props .grid-flex h2 {
        font-size: 3rem;
        text-transform: unset;
    }

    .dance-links p {
        font-size: 1.4rem;
        line-height: 1.7;
        margin-bottom: 5px;
    }

    .dance-links.sec-dance-links .grid-flex {
        grid-gap: 30px;
    }

    .testimonial-data {
        padding: 15% 0px;
    }

    .dance-props .grid-flex p,
    .dance-props .grid-3 p {
        font-size: 1.4rem;
    }
}

/* sec-props */
.sec-props {
    background: #E6DFD0;
    padding: 5% 0px;
}

.sec-props .grid-4 img {
    border-radius: 4px;
}

.sec-props .grid-4 p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 0px;
}

.sec-props .grid-4 p.props-count {
    margin-bottom: 0px;
    font-size: 1.3rem;
}

.hero-sec-props-bg {
    background: #000;
    background: #68665C;
    background: #E6DFD0;
    padding: 5% 0px;
}

.hero-sec-props-bg p {
    color: #fff;
    line-height: 1.8;
    font-size: 1.6rem;
    color: #000;
}

.hero-sec-props-bg h2 {
    font-size: 3rem;
    width: 100%;
    color: #fff;
    color: #000;
    /* color: #fbdfb4; */
}

.hero-sec-props-bg p.hero-summary {
    margin-bottom: 0px;
    font-size: 1.2rem;
}

.hero-sec-props-bg .grid-2 .grid-2 p {
    font-size: 1.3rem;
    line-height: 1.8;
    color: rgba(253, 253, 253, .9);
    color: #000;
}

.hero-sec-props-bg .grid-2 .grid-2 h4 {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 0px;
    color: rgba(253, 253, 253, .9);
    color: #000;
}

.hero-sec-props-bg .grid-2 .grid-2 div {
    border-radius: 16px;
    border: 1px solid #A9A1AA;
    padding: 10px;
}

.testimonial-carousel .text-bloc {
    background: #000;
    padding: 20px;
    border-radius: 16px;
}

.testimonial-carousel .text-bloc._1 {
    background: #0C2481;
}

.testimonial-carousel .text-bloc._2 {
    background: #611F69;
}

.testimonial-carousel .text-bloc._3 {
    background: #0F9D58;
}

.testimonial-carousel .text-bloc._4 {
    background: #0061D5;
}

.testimonial-carousel .text-bloc p {
    color: #fff;
    font-size: 1.4rem;
    line-height: 1.8;
}

.testimonials-list .contain p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 40px;
    width: 50%;
}

.testimonials-list .contain p.tile-summary {
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 0px;
}

p.tile-summary {
    /* background: var(--red); */
    padding-bottom: 10px;
    font-family: 'Gilroy Bold';
    width: max-content !important;
    border-radius: 50px;
    color: #fff;
    color: var(--red);
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 0px;
}

.testimonials-list .contain h3 {
    font-size: 3rem;
    width: 40%;
}

.testimonials-list .testimonial-list-author {
    margin-top: 5%;
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.testimonials-list .testimonial-list-author .avatar {
    background: var(--red);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.testimonials-list .testimonial-list-author h4 {
    margin-bottom: 0px;
    color: #fff;
}

.testimonials-list .testimonial-list-author p {
    margin-bottom: 0px;
}

.testimonials-list h2 {
    color: #fff;
    /* height: 100px; */
    /* font-size: 30rem; */
}

.testimonials-list .testimonial-main-cover .testimonial-main-1 {
    position: absolute;
    right: 30px;
    top: 0px;
}

.testimonials-list .testimonial-main-cover .testimonial-main-1._2 {
    position: absolute;
    right: 80px;
    top: 50px;
}

.testimonials-list {
    position: relative;
}

.testimonials-list .testimonial-main-1 {
    background: #F4EDE4;
    /* border: 2px solid; */
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.fake-img-cover {
    height: 100%;
    width: 100%;
    background: #FBFAF8;
    border-radius: 8px;
}

.video-display-sect h2 {
    font-size: 3.2rem;
    margin-bottom: 10px;
}

.center-div .btn-array button.btn-default {
    border: 2px solid #212529;
    color: #212529;
}

@media screen and (max-width:720px) {
    .hero-sec-props-bg {
        padding: 15% 0px;
    }

    .hero-sec-props-bg .grid-2:first-child {
        display: flex;
        flex-direction: column-reverse;
    }

    .sec-props .contain .grid-4 {
        display: flex;
        flex-direction: column;
    }

    .hero-sec-props-bg h2 {
        font-size: 3rem;
        width: 100%;
        color: #fff;
    }

    .testimonials-list .contain h3 {
        font-size: 3rem;
        width: 100%;
    }

    .testimonials-list .contain p {
        /* font-size: 3rem; */
        width: 100%;
    }

    .fake-img-cover {
        height: 30rem;
        width: 100%;
        background: #FBFAF8;
        border-radius: 8px;
    }

    .hero-sec-props-bg .grid-2 .grid-2 {
        display: block;
    }

    .hero-sec-props-bg .grid-2 .grid-2 p {
        font-size: 1.4rem;
    }

    .hero-sec-props-bg .grid-2 .grid-2 div {
        border-radius: unset;
        border: none;
        border-top: 3px solid #A9A1AA;
        padding: 0px;
    }
}

/* rubbish */
.props-copy-img {
    background: #FBFAF8;
    height: 30rem;
    width: 100%;
    border-radius: 4px;
}

.sec-props-copy {
    background: #68665C;
    padding: 5% 0px;
}

.sec-props-copy .grid-2 {
    align-items: center;
}

.sec-props-copy button {
    background: #FBDFB4;
    border: 1px solid #FBDFB4;
    padding: 15px 25px;
    color: #000;
    border-radius: 4px;
    font-size: 1.6rem;
}

.sec-props-copy p {
    font-size: 1.6rem;
    line-height: 1.8;
    color: #fff;
}

.sec-props-copy h2 {
    font-size: 3rem;
    color: #fbdfb4;
}

.sec-testimonial-copy {}

.sec-app-props-copy {
    background: #FBFAF8;
    padding: 5% 0px;
    text-align: center;
}

h3.main-text {
    margin-bottom: 70px;
    font-size: 3rem;
    text-align: center;
}

.sec-app-props-copy .app-props-copy-img {
    background: #DEAA94;
    height: 50px;
    width: 50px;
    margin: 0px auto;
    display: block;
    margin-bottom: 20px;
}

.sec-app-props-copy p {
    font-size: 1.4rem;
}

.company-showcase img {
    width: 5%;
    aspect-ratio: 3/2;
    object-fit: contain;
    /* mix-blend-mode: color-burn; */
}

.company-showcase .company-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 30px;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 60px;
    margin-bottom: 0px;
}

.company-showcase .company-images-list {
    width: 80%;
    margin: 0px auto;
    display: block;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.company-showcase .company-images-list p {
    font-size: 1.6rem;
    margin-bottom: 40px;
}


.company-story-props {
    background: #FBFAF8;
    padding-bottom: 5%;
}

.company-story-props .about-sub-props .grid-2 {
    margin-bottom: 0px;
}

/*  */
.new-hero-sect {
    background: #202124;
    background: #121212;
    padding: 5% 0px;
    padding-bottom: 30%;
    position: relative;
}

.new-hero-sect .new-hero-block {
    border: 1px solid rgba(253, 253, 253, .2);
    border-radius: 3px;
}

.new-hero-sect .new-hero-block p {
    font-size: 1.4rem;
    color: rgba(253, 253, 253, .8);
    line-height: 1.8;
}

.new-hero-sect .new-hero-block h4 {
    font-size: 1.6rem;
    color: #fff;
    line-height: 1.3;
    margin-bottom: 5px;
}

.new-hero-sect h2 {
    font-size: 3.2rem;
    color: #fff;
    margin-bottom: 0%;
}

.new-hero-sect .grid-2 p {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0px;
    line-height: 1.6;
}

.new-hero-sect .grid-2 {
    margin-bottom: 20px;
    align-items: end;
}

.new-hero-sect .new-text-block {
    padding: 5% 15px;
    padding-bottom: 2%;
}

.new-hero-sect .grid-3 img {
    height: 20rem;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}


/* white-sect */
.white-sect {
    height: 32rem;
    background: #fff;
    background: linear-gradient(to bottom, #EBC9A0, #EEEDEF, #EFEEEF);
}

.white-sect.last {
    height: 25rem;
}

.company-testimonial {
    /* border: 1px solid #fff; */
}

.company-testimonial .company-grid-testimonial-block {
    height: 15rem;
    border: 1px solid #fff;
    border-left: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-testimonial .company-grid-testimonial-block img {
    width: 30%;
    height: auto;
    /* height: 15rem;
    border: 1px solid #fff;
    border-left: unset; */
}

.company-testimonial .company-grid-testimonial-block.bottom {
    border-top: unset;
}

.company-testimonial .company-grid-testimonial-block.left {
    border-left: 1px solid #fff;
}

.company-testimonial .grid-4 {
    grid-gap: 0px;
}

.person-testimonial .grid-2-bias {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.person-testimonial .grid-2-bias .person-picture {
    background: gray;
}

.person-testimonial .grid-2-bias p.testi-author {
    font-size: 1.6rem !important;
}

.person-testimonial .grid-2-bias .text-bg {
    background: #fff;
    padding: 5% 10px;
}

.person-testimonial .grid-2-bias .text-bg p {
    font-size: 1.7rem;
    line-height: 1.8;
}

.person-testimonial .owl-stage-outer {
    margin-left: -80px;
}


/* black-bg */
.black-bg {
    background: #000;
    height: 45rem;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.black-bg ion-icon {
    color: #fff;
    font-size: 8.5rem;
}

.new-hero-ideo-block {
    /* margin-top: -15%; */
    position: absolute;
    bottom: -25%;
    width: 100%;
}


.new-hero-sect.testimonial-cut {
    padding-bottom: 15%;
}

.person-testimonial {
    position: absolute;
    bottom: -30%;
    width: 100%;
}

.person-testimonial .contai {
    padding-left: 3%;
}

/* 0061EF */
.sec-homepage-hero {
    background: #0061EF;
    padding: 3% 0px;
}

.sec-homepage-hero .grid-2 {
    align-items: center;
}

.sec-homepage-hero img {
    width: 70%;
    height: 100%;
    margin: 0px auto;
    display: block;
}

.sec-homepage-hero p {
    font-size: 1.7rem;
    color: #fff;
    line-height: 1.8;
    margin-bottom: 20px;
}

.sec-homepage-hero button {
    background: var(--red);
    padding: 15px 35px;
    border-radius: 50px;
    border: 1px solid var(--red);
    color: #fff;
    font-size: 1.5rem;
}

.sec-homepage-hero button.impact {
    background: #fff;
    color: var(--red);
    border: 1px solid #fff;
}

.sec-homepage-hero .button-grid {
    grid-gap: 10px;
    display: flex;
}

.sec-homepage-extra-desc .button-grid .btn-yellow.curve {
    display: flex;
    grid-gap: 10px;
}

.sec-homepage-extra-desc .button-grid .btn-yellow.curve svg {
    height: 2rem;
}

.sec-homepage-hero h2 {
    color: #fff;
    font-size: 4rem;
}

.sec-homepage-hero h2:nth-child(2) {
    margin-bottom: 10px;
}

/* sec-homepage-props */
.sec-homepage-props {
    background: #fff;
}

.sec-homepage-props .grid-3 .sec-props-detail {
    background: #F9F4F2;
    padding: 15px 10px;
    padding: 0px;
    border-radius: 4px;
}

.sec-homepage-props .grid-3 img {
    height: 25rem;
    object-fit: cover;
    object-position: center;
}

.sec-homepage-props .grid-3 .sec-text-detail {
    padding: 10px;
}

.sec-homepage-props .grid-3 .sec-props-detail p {
    color: #111;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 0px;
}

.sec-homepage-props .grid-3 .sec-props-detail h4 {
    color: #111;
    font-size: 1.6rem;
    /* line-height: 1.6; */
    margin-bottom: 5px;
    width: 80%;
    line-height: 1.4;
}

.sec-homepage-props .main-content {
    text-align: center;
}

.sec-homepage-props .main-content p {
    font-size: 1.6rem;
    line-height: 1.6;
    width: 45%;
    margin: 0px auto;
    display: block;
    margin-bottom: 3%;
}

.sec-homepage-props .main-content h2 {
    font-size: 3.2rem;
    /* padding-top: 5%; */
}

.sec-homepage-props .grid-4 img {
    width: 30%;
    height: auto;
    margin-bottom: 30px;
}

.sec-homepage-rolling-text {
    background: #121212;
    padding: 15px 0px;
    width: 100%;
    display: block;
}

/* sec-homepage-desc */
.sec-homepage-desc {
    background: #111;
    background: var(--off-green);
    padding: 6% 0px;
}

.sec-homepage-desc p {
    font-size: 1.6rem;
    line-height: 1.8;
    color: #fff;
}

.sec-homepage-desc h2 {
    font-size: 3.5rem;
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
}

.sec-homepage-desc .grid-2 {
    align-items: center;
}

.sec-homepage-desc .grid-2 img {
    width: 70%;
    height: 100%;
    margin: 0px auto;
    display: block;
}

.sec-homepage-desc .btn-array {
    flex-wrap: wrap;
}

/* .card-display */

.center-div {
    text-align: center;
    width: 90%;
    margin: 0px auto;
    display: block;
    margin-bottom: 30px;
}

.paymentModal.video-player-payment .center-div {
    width: 100%;
}

.center-div .btn-array {
    justify-content: center;
    flex-wrap: wrap;
}

.card-display {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .2);
}

.card-display .card-header {
    background: #FBDFB4;
    height: auto;
    width: 100%;
    border-radius: 8px 8px 0px 0px !important;
    position: relative;
}

.card-display .grid-4 .card-header {
    height: 13rem;
}

.card-display .card-header .card-overlay {
    background: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0px;
    height: 100%;
    left: 0px;
    right: 0px;
    border-radius: 4px 4px 0px 0px;
    z-index: 10;
}

.dash-main-div .dash-main-content .grid-4 {
    /* grid-gap: 10px; */
}

.card-display .card-header-fee {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}

.card-display .card-header-fee .card-header-cover {
    background: #0061EF;
    background: var(--yellow);
    box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, .2);
    border-radius: 50px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.card-display .card-header-fee ion-icon {
    color: #fff;
    color: #000;
    font-size: 1.4rem;
}

.card-display .card-header-fee svg {
    height: 1.5rem;
    width: auto;
}

.tag-block {
    grid-gap: 10px;
    display: flex;
    margin-bottom: 20px;
}

/* .tag-block button {
    padding: 8px 25px;
    border-radius: 3px;
    font-size: 1.4rem;
    background: transparent;
} */

.tag-block button.active {
    background: var(--red);
    border: 1px solid var(--red);
    color: #fff;
}

.card-display .card-body .card-body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 4px;
}

.card-display a {
    text-decoration: none;
}

.grid-4 .card-display .card-header img,
.grid-3 .card-display .card-header img {
    height: 100%;
    height: 15rem;
    border-radius: 4px 4px 0px 0px;
}

.card-display .card-body {
    padding: 10px;
    background: rgba(255, 255, 255, .1);
    position: relative;
    /* background: red; */
}

.card-display .card-body .card-body-header p {
    font-size: 1.2rem;
    color: #000;
    margin-bottom: 0px;
}

.card-display .card-body h4.card-body-title {
    font-size: 1.5rem;
    color: #000;
    margin-bottom: 0px;
}

.card-display .card-body .card-body-title-cover {
    height: 40px;
}

.main-video-content .grid-3 a {
    text-decoration: none;
}

.card-display .card-body .card-header p {
    font-size: 1.2rem;
    color: #000;
}

.card-display .card-body .card-body-footer {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.card-display .card-body .card-body-footer p {
    color: #000;
    font-size: 1.2rem;
    margin-bottom: 0px;
}

/* sec-homepage-extra-desc */
.sec-homepage-extra-desc p {
    font-size: 1.6rem;
    line-height: 1.8;
}

.sec-homepage-extra-desc h2 {
    font-size: 3rem;
    width: 70%;
}

.sec-homepage-extra-desc .grid-first-outline.pt_5 h2 {
    width: 80%;
}

.sec-homepage-extra-desc img {
    width: 80%;
    margin: 0px auto;
    display: block;
    border-radius: 4px;
}

.sec-homepage-extra-desc div.sec-homepage-img-div {
    width: 100%;
    height: 35rem;
    border-radius: 4px;
    background: #F3F4F7;
}

.sec-homepage-extra-desc .grid-2.grid-first-outline {
    grid-gap: 50px;
}

/* .sec-homepage-extra-desc .grid-2 .grid-2 p {
    margin-bottom: 0px;
    font-size: 1.6rem;
    line-height: 1.8;
} */

.sec-homepage-extra-desc .grid-2 .grid-2 p {
    margin-bottom: 0px;
    font-size: 1.4rem;
    line-height: 1.8;
    display: flex;
    align-items: flex-start;
    grid-gap: 5px;
}

.sec-homepage-extra-desc .grid-2 .grid-2 p svg {
    height: 20px;
    width: auto;
}

.sec-homepage-extra-desc .grid-2 {
    grid-gap: 0px;
    margin-bottom: 20px;
    margin-bottom: 0px;
    align-items: center;
}

.sec-homepage-extra-desc .grid-2 .grid-2 {
    grid-gap: 5px;
    margin-bottom: 20px;
}

.sec-homepage-extra-desc .grid-2 ion-icon {
    margin-right: 10px;
    font-size: 2rem;
    box-shadow: 3px 0px 1px rgba(212, 51, 97, 1);
    border-radius: 50px;
}



/* scrolling text */
.scroll-container p {
    color: #fff;
    margin-bottom: 0px;
    font-size: 1.3rem;
}

/* .scroll-container ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.scroll-container ul li {
    color: #fff;
    width: max-content;
    font-size: 1.4rem;
    display: inline-block;
    padding-right: 20px;
}

.scroll-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.scroll-text {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: my-animation 30s linear infinite;
    -webkit-animation: my-animation 30s linear infinite;
    animation: my-animation 30s linear infinite;
}

@-moz-keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
    }

    to {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateX(100%);
    }

    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
} */


@media screen and (max-width: 720px) {
    .sec-homepage-hero {
        padding: 15% 0px;
    }

    .sec-homepage-hero .grid-2 {
        display: flex;
        flex-direction: column;
        /* flex-direction: column-reverse; */
        align-items: unset;
        grid-gap: 40px;
    }

    .sec-homepage-hero h2 {
        font-size: 3rem;
    }

    .sec-homepage-hero p {
        font-size: 1.5rem;
        line-height: 1.7;
    }

    .sec-homepage-hero button {
        padding: 12px 25px;
        font-size: 1.4rem;
    }

    .sec-homepage-hero img {
        margin: unset;
        width: 80%;
    }

    .sec-homepage-props .main-content h2 {
        font-size: 2.5rem;
    }

    .sec-homepage-props .main-content {
        text-align: unset;
    }

    .sec-homepage-props .main-content p {
        width: 100%;
        font-size: 1.5rem;
        margin-bottom: 40px;
    }

    .sec-homepage-props .grid-4 img {
        width: 20%;
        margin-bottom: 20px;
    }

    .sec-homepage-props .grid-4 .sec-props-detail h4 {
        /* font-size: 1.8rem; */
    }

    .sec-homepage-desc {
        padding: 15% 0px;
    }

    .sec-homepage-desc h2 {
        font-size: 2.5rem;
        text-align: unset;
    }

    .sec-homepage-desc .grid-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    .sec-homepage-extra-desc .grid-2.grid-first-outline {
        display: flex;
        flex-direction: column-reverse;
    }

    .sec-homepage-extra-desc.reverse .grid-2.grid-first-outline {
        display: flex;
        flex-direction: column;
    }

    .sec-homepage-props .grid-4 {
        display: flex;
        flex-direction: column;
    }

    .sec-homepage-desc .center-div {
        width: 100%;
        text-align: unset;
    }

    .sec-homepage-extra-desc div.sec-homepage-img-div {
        height: 30rem;
        margin-bottom: 0px;
    }

    .center-div .btn-array {
        justify-content: left;
        grid-gap: 0px;
    }

    .sec-homepage-extra-desc p {
        font-size: 1.5rem;
        line-height: 1.6;
    }

    .sec-homepage-extra-desc h2 {
        font-size: 2.5rem;
        width: 80%;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 p {
        font-size: 1.5rem;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .final-cover {
        padding: 10% 0px;
    }

    .card-display .card-body .card-body-title-cover {
        height: 30px;
    }

    .card-display .card-body h4.card-body-title {
        font-size: 1.3rem;
    }

    .card-display .card-body .card-body-footer p,
    .card-display .card-body .card-body-header p {
        font-size: 1rem;
    }
}


/*  */
.new-homepage h1,
.new-homepage h2,
.new-homepage h3,
.new-homepage h4,
.new-homepage h5 {
    font-family: 'Recife Regular';
}

.home-sect {
    background: #26368A;
    align-items: center;
    text-align: center;
    padding: 15% 0px;
}

.home-sect h1 {
    font-size: 4rem;
    color: #fff;
    margin-bottom: 20px;
}

.home-sect p {
    font-size: 1.6rem;
    width: 50%;
    margin: 0px auto;
    display: block;
    color: #fff;
    margin-bottom: 20px;
}

.home-sect a {
    background: #fff;
    border: 2px solid #111;
    padding: 10px 25px;
    border-radius: 50px;
    font-size: 1.4rem;
    text-decoration: none;
}

.new-homepage-dance-props {
    background: #F2EAE3;
    padding: 10% 0px;
}

.new-homepage-dance-props p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.new-homepage-dance-props .page-summary {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.new-homepage-dance-props h3 {
    font-size: 4rem;
    margin-bottom: 10px;
}

.new-homepage-dance-props .grid-2 {
    align-items: center;
}

.new-homepage-dance-props button {
    background: #26368A;
    padding: 10px 25px;
    border-radius: 50px;
    border: 1px solid #26368A;
    color: #fff;
    font-size: 1.4rem;
}

.new-homepage-dance-props .grid-2 img {
    border-radius: 4px;
    height: auto;
    width: 60%;
    margin: 0px auto;
    display: block;
}

.new-homepage-dance-props.meal-props {
    background: #299490;
}

.new-homepage-dance-props.dance-story {
    background: #26368A;
}


/*  */
/*  */
.real-homepage .hero-section {
    background: #10162F;
    background: #002333;
    /* background: #432821; */
    /* min-height: 70vh; */
    padding: 40px 0px;
    padding: 5% 0px;
    display: flex;
    align-items: center;
}

.real-homepage .hero-section .grid-2 {
    /* text-align: center; */
    align-items: center;
}

.real-homepage .hero-section .grid-2 h2 {
    font-size: 5.5rem;
    color: #fff;
}

/* .real-homepage */

.real-homepage .hero-section .grid-2 p {
    font-size: 1.6rem;
    color: #fff;
    line-height: 1.8;
}

.companies-tab {
    display: flex;
    grid-gap: 10px;
    opacity: .6;
    /* flex-wrap: wrap; */
}

.companies-tab .company-1 {
    background: #E7AB54;
    height: 40px;
    width: 120px;
    border-radius: 4px;
}

.companies-tab img {
    width: auto;
    height: 40px;
}

.real-homepage .hero-section .grid-2 button {
    font-size: 1.5rem;
    font-family: 'Gilroy Bold';
    color: #10162F;
}

.real-homepage .colored.dance-props-sect.first-div {
    background: #10162F;
    padding: 5% 0px;
}

.real-homepage .colored.dance-props-sect.first-div h3,
.real-homepage .colored.dance-props-sect.first-div h5 {
    color: #fff;
}

.real-homepage .colored.dance-props-sect.first-div p {
    color: rgba(253, 253, 253, .8);
}

.real-homepage .dance-props-sect.first-div h2 {
    font-size: 3rem;
    /* color: #fff; */
    margin-bottom: 10px;
}

.real-homepage .dance-props-sect.first-div .btn-default {
    border: 2px solid #212529;
    color: #212529;
}

.real-homepage .card-display .card-header img {
    height: 15rem;
}

.dance-props-sect,
.video-display-sect {
    background: #E8E2D6;
    background: rgba(232, 226, 214, .2);
    /* background: #F5FCFF; */
    padding: 5% 0px;
}

.dance-props-sect .grid-3 p {
    font-size: 1.5rem;
    line-height: 1.8;
}

.dance-props-sect .grid-3 h5 {
    font-size: 1.8rem;
}

.dance-props-sect .grid-3 {
    grid-gap: 35px;
}

.dance-props-sect .grid-3 img {
    width: auto;
    height: 8rem;
    margin-bottom: 15px;
}

.dance-props-sect .first-container h3 {
    font-size: 2.2rem;
    margin-bottom: 0px;
}

.dance-props-sect .first-container p {
    font-size: 1.8rem;
    width: 50%;
    line-height: 1.7;
    margin-bottom: 0px;
}

.dance-props-sect .first-container {
    margin-bottom: 30px;
}

.dance-props-sect .grid-2 p {
    font-size: 1.7rem;
    font-size: 1.5rem;
    line-height: 1.7;
    line-height: 1.8;
    color: #10162f;
}

.dance-props-sect .grid-2 h3 {
    font-size: 2.5rem;
    width: 70%;
    line-height: 1.5;
}

/* .real-homepage */

/* .real-homepage p {
    font-family: 'Graphik';
} */

.about-us-page .hero-section img {
    width: 60%;
    height: auto;
    margin: 0px auto;
    display: block;
}

.about-us-page .hero-section h2,
.homepage-content .hero-section h2 {
    margin-bottom: 15px;
}

.about-us-page .hero-section p,
.homepage-content .hero-section p {
    margin-bottom: 30px;
}

.about-us-page .hero-section a.btn-yellow,
.homepage-content .hero-section a.btn-yellow {
    display: flex;
    text-decoration: none;
    align-items: center;
    grid-gap: 10px;
    border-radius: 50px;
    width: max-content;
}

.about-us-page .hero-section a.btn-yellow,
.homepage-content .hero-section a.btn-yellow {
    padding: 14px 25px;
}

.about-us-page .hero-section .btn-flex,
.homepage-content .hero-section .btn-flex {
    /* display: grid;
    width: max-content;
    grid-template-columns: repeat(2, 1fr);
    align-items: center; */

    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.about-us-page .hero-section a.btn-blank,
.homepage-content .hero-section a.btn-blank {
    font-size: 1.4rem;
    color: var(--yellow);
    color: #fff;
    text-decoration: none;
    margin-bottom: 0px;
    border: 2px solid var(--yellow);
    border-radius: 50px;
    padding: 15px 25px;
}

.about-us-page .hero-section a.btn-yellow svg,
.homepage-content .hero-section a.btn-yellow svg {
    width: auto;
    height: 2.5rem;
}

.video-display-sect.dance-props-sect h3,
.video-display-sect.dance-props-sect h5 {
    color: #fff;
}

.video-display-sect.dance-props-sect p {
    color: rgba(253, 253, 253, .8);
}

@media screen and (max-width: 720px) {
    .real-homepage .hero-section .grid-2 {
        grid-template-rows: unset;
        display: block;
    }

    .about-us-page .hero-section .btn-flex,
    .homepage-content .hero-section .btn-flex {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .real-homepage .hero-section .grid-2 div:first-child {
        margin-bottom: 20px;
    }

    .homepage-content .hero-section a {
        width: 100% !important;
        padding: 12px 15px !important;
        display: flex;
        grid-gap: 0px !important;
        justify-content: center !important;
    }

    .homepage-content .hero-section a svg {
        padding-left: 10px;
    }

    .real-homepage .hero-section .grid-2 h2 {
        font-size: 3rem;
    }

    .real-homepage .hero-section .grid-2 p {
        font-size: 1.5rem;
    }

    .real-homepage .card-display .card-header img {
        height: 18rem;
        border-radius: 8px 8px 0px 0px;
        width: 100%;
    }

    .real-homepage .hero-section .grid-2 {
        align-items: unset;
    }

    .real-homepage .hero-section {
        padding: 15% 0px;
    }

    .real-homepage .dance-props-sect .grid-2 {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }

    .dance-props-sect .grid-3 {
        flex-direction: column;
        display: flex;

        display: grid;
        grid-template-columns: unset;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 30px;
    }

    /* .real-homepage .video-display-sect {
        padding: 15% 0px;
    } */

    .real-homepage .center-div {
        width: 100%;
        text-align: unset;
    }

    .real-homepage .center-div .btn-array .btn-default {
        font-size: 1.1rem;
        padding: 6px 10px;
        margin-right: 5px;
        margin-bottom: 8px;
    }

    .real-homepage .video-display-sect h2 {
        font-size: 2.8rem;
    }

    .real-homepage .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }

    .dance-props-sect.sec-homepage-extra-desc .grid-2.grid-first-outline {
        flex-direction: column-reverse;
        grid-gap: 0px;
    }

    .dance-props-sect.sec-homepage-extra-desc .grid-2.grid-first-outline img {
        margin-bottom: 20px;
    }

    .dance-props-sect .grid-2 p {
        font-size: 1.5rem;
        line-height: 1.8;
        color: #10162f;
        margin-bottom: 20px;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 p {
        grid-gap: 0px;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 p svg {
        margin-right: 10px;
    }

    .sec-homepage-extra-desc .button-grid .btn-yellow.curve {
        grid-gap: 0px;
        padding: 12px 25px;
    }

    .sec-homepage-extra-desc .button-grid .btn-yellow.curve svg {
        padding-left: 10px;
    }

    .dance-props-sect .first-container {
        margin: 0px;
    }

    .dance-props-sect .grid-3 p {
        margin-bottom: 0px;
    }

    .dance-props-sect.first-div .mt_5 {
        margin-top: 0px;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 p {
        font-size: 1.4rem;
    }

    .sec-homepage-extra-desc .grid-2 .grid-2 {
        align-items: unset;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .sec-homepage-extra-desc .grid-2.grid-first-outline.pt_5 {
        flex-direction: column;
        padding-top: 15%;
    }

    .sec-homepage-extra-desc .grid-2.grid-first-outline.pt_5 .grid-2,
    .sec-homepage-extra-desc .grid-2.grid-first-outline.adjust .grid-2 {
        flex-direction: column;
        display: flex;
    }

    .dance-props-sect.first-div {
        text-align: center;
    }

    /* .dance-props-sect  */
    .real-homepage .colored.dance-props-sect.first-div {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .dance-props-sect {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .sec-homepage-extra-desc img {
        width: 100%;
        margin-bottom: 0px;
    }

    .dance-props-sect .grid-2 h3 {
        margin: 0px auto;
        width: 100%;
        line-height: 1.3;
        margin-bottom: 10px;
        font-size: 2.3rem;
    }


    .about-us-page .hero-section img {
        margin: unset;
    }

    .about-us-page .hero-section a.btn-yellow {
        padding: 10px 25px;
    }

    .about-us-page.real-homepage .dance-props-sect .grid-2 {
        grid-gap: 0px;
    }

    .about-us-page.real-homepage .dance-props-sect .grid-2 p {
        line-height: 1.8;
    }

    .about-hero-sec .testimonial h1 {
        line-height: 1.5;
    }

    .testimonial .testimonial-author-sect h3 {
        font-size: 1.6rem;
    }

    .testimonial .testimonial-author-sect p {
        margin-bottom: 0px;
        font-size: 1.4rem;
    }

    .companies-tab {
        display: none;
    }

    .video-display-sect {
        padding: 15% 0px;
        padding-bottom: 10%;
    }

    .video-display-sect .swiper {
        padding-bottom: 5%;
    }
}