.contact .grid_2 {
    grid-gap: 150px;
}

.contact_div h3 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact p {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.contact_div .sticky_social {
    margin-top: 20px;
}

.contact .grid-2 {
    grid-gap: 60px;
}

.contact_div ion-icon {
    color: var(--red);
    font-size: 2.5rem;
    margin-right: 10px;
}

/* auth */
.auth-display {
    min-height: 85vh;
    display: flex;
    width: 100%;
    padding-top: 0%;
}

.auth-display.account-verification {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #F4F4F4;
}

.auth-display.account-verification p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.auth-display.account-verification .message-div {
    background: #fff;
    width: 50%;
    padding: 10px 15px;
    padding-top: 20px;
    border-radius: 4px;
    margin: 0px auto;
    display: block;
}

.auth-display.account-verification.set-password .auth-display-center .message-div {
    width: 50% !important;
}

.auth-display.account-verification .message-div a,
.auth-display.account-verification .message-div button {
    background: var(--green);
    border: 1px solid var(--green);
    color: #fff;
    font-size: 1.4rem;
    text-decoration: none;
    padding: 12px 25px;
    border-radius: 4px;
    border-radius: 50px;
    display: block;
    width: max-content;
    margin: 0px auto;
    margin-bottom: 30px;
}

.auth-display.account-verification.sendmail .message-div a {
    background: transparent;
    border: none;
    display: inline;
    color: var(--green);
    padding: 0px;
}

.auth-display.account-verification.sendmail .message-div h3 {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.auth-display.account-verification.sendmail .message-div p {
    margin-bottom: 20px;
}


.auth-display.account-verification.sendmail .message-div .form_detail {
    padding-top: 0px;
}

.auth-display.account-verification .message-div ion-icon {
    font-size: 8rem;
}

.auth-display.account-verification .message-div h4 {
    font-size: 1.8rem;
}

/* .auth-display form {
    width: 80%;
} */

.auth-display .grid-2 {
    height: 100%;
    width: 100%;
    height: inherit;
    align-items: center;
}

.auth-image-bg {
    background: #000;
    background: url('../images/content/signup.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    position: relative;

    /*  */
    background: #1C212C;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    overflow: hidden;
    padding: 0px 5%;
}

.auth-image-bg h2 {
    font-size: 4rem;
    width: 90%;
    margin-bottom: 30px;
    color: #fff;
    text-align: left;
}

.auth-image-bg p {
    font-size: 1.6rem;
    margin-bottom: 30px;
}

.auth-image-bg .logo {
    position: absolute;
    top: 20px;
    left: 20px;
}

.auth-image-bg .bubble {
    position: absolute;
    background: #FBE4D1;
    top: -40px;
    right: -40px;
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.auth-image-bg .bubble._2 {
    background: #CFD1FF;
    bottom: -40px;
    top: unset;
    left: -40px;
}

.auth-image-bg img {
    height: 50px;
    width: 100px;
    border-radius: 4px;
}

.auth-image-bg ion-icon {
    font-size: 3rem;
}

.auth-image-bg .socials {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}

.auth-image-bg .signup-text {
    position: absolute;
    bottom: 0px;
    z-index: 100;
}

.auth-image-bg .signup-text p {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    line-height: 1.6;
}

.auth-image-bg .signup-text h3 {
    font-size: 2.3rem;
    color: #fff;
}

.auth-image-bg .signup-text-cover {
    display: block;
    text-align: center;
    width: 75%;
    margin: 0px auto;
    display: block;
}

.bg_dark {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1), rgba(0, 0, 0, .6));
    border-radius: 0px;
}

.form_detail {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, .2);
    box-shadow: rgba(0, 0, 0, 0.03) 0px 30px 60px;
    padding: 30px 30px 15px;
    /* padding-top: 10%; */
    /* width: max-content; */
    border-radius: 4px;
}

.signin .form_detail {
    width: 70%;
}

.auth-form-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-form-content button.btn-red {
    width: 100%;
}

.external-auth {
    text-align: center;
}

.external-auth p {
    margin-bottom: 0px;
}

.external-auth p.no-margin {
    margin-bottom: 10px;
}

.form_detail h3 {
    font-size: 2.2rem;
    padding-bottom: 10px;
}

.form_detail .first-display p {
    font-size: 1.4rem;
    padding-bottom: 20px;
    margin-bottom: 0px;
}

.form_detail form button {
    margin-bottom: 30px;
}

.form_detail form p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.help-page .grid-3 a {
    border: 1px solid #FF7E1D;
    padding: 15px 25px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1.5rem;
    text-align: center;
    color: #FF7E1D;
    /* font-family: 'Gilroy Bold'; */
}

@media screen and (max-width: 720px) {
    .auth-display .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .auth-image-bg {
        height: unset;
        padding: 40px 3%;
    }

    .auth-display {
        height: unset;
        min-height: unset;
    }

    .auth-display form {
        width: 100%;
    }

    .contact .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .contact .grid-4,
    .contact .grid-5 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .auth-image-bg h2 {
        font-size: 2.5rem;
        width: 100%;
        margin-bottom: 10px;
        line-height: 1.5;
    }

    .form_detail {
        padding: 0px 3%;
        padding-bottom: 15%;
        width: 100%;
    }

    .signin .form_detail {
        width: 100% !important;
    }

    .form_detail p,
    .form_detail label,
    .form_detail a {
        font-family: 'Graphik';
    }

    .form_detail h3 {
        font-size: 2rem;
        margin-bottom: 5px !important;
    }

    .form_detail .first-display p {
        font-size: 1.5rem;
        line-height: 1.8;
    }

    .auth-display.account-verification .message-div {
        padding: 0px 3%;
        width: 100% !important;
    }
    .auth-display-center {
        background: #fff;
    }
    .auth-display.account-verification.sendmail .message-div .form_detail {
        padding: 0px;
        padding-bottom: 15%;
    }
    .auth-display.account-verification {
        flex-direction: column;
        text-align: left;
    }
    .auth-display.account-verification.sendmail .message-div h3 {
        padding-top: 10%;
    }
}