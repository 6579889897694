:root {
    --red: #d43361;
    --black: #000000;
    --green: #0E8127;
    --yellow: #E7AB54;
    --off-green: #002333;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'Recife Regular';
    src: url('../fonts/recife/RecifeText-Regular.ttf');
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/graphik/GraphikRegular.otf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/Gilroy-FREE//Gilroy-FREE/Gilroy-Medium.ttf');
}

@font-face {
    font-family: 'Ambit Black';
    src: url('../fonts/Ambit/Ambit-Black.ttf');
}

* {
    /* margin: 0px;
    padding: 0px; */
    font-family: 'Gilroy Regular';
}

ul {
    margin: 0px;
    padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: 'Ambit Black'; */
    font-family: 'Gilroy Bold';
    font-weight: normal;
    word-spacing: -1px;
}

body,
html {
    font-size: 10px;
    font-family: 'Gilroy Regular';
    background-color: rgba(245, 245, 245, .1);
    overflow-x: hidden;
    max-width: 1540px;
    margin: 0px auto;
    display: block;
}

button {
    font-family: 'Gilroy Regular';
}

/* navigation */
.navigation .nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 1.5%;
    align-items: center;
    position: relative;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 4px -1px rgba(57, 76, 96, .15);
    background: #fff !important;
    /* background: rgba(248, 244, 242, .3);
    background-color: rgba(245, 245, 245, .1); */
    z-index: 1000;
}

/* .navigation .menu {
    height: 20px;
    width: auto;
} */

.navigation svg {
    height: 2.5rem;
}

.navigation .logo img {
    /* height: 50px;
    width: 90px; */
    /* background: #000; */
    height: 50px;
    width: 100px;
    border-radius: 3px;
}

.navigation .inner-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 30px;
}

.navigation.fixed {
    position: fixed;
    top: 0px;
    width: 100%;
    background: #fff;
    /* background: #082B3B !important; */
    box-shadow: none;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .1);
    z-index: 1000;
}

.navigation ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    margin-bottom: 0px;
}

.navigation ul li a {
    /* color: #fff; */
    text-decoration: none;
    font-size: 1.4rem;
    color: #111;
}

.navigation ul li a {
    position: relative;
    z-index: 1000;
}

.navigation ul li .nav-text {
    z-index: 10000;
    position: relative;
}

.navigation ul li .active-link .cover-nav-bg {
    background: var(--yellow);
    height: 15px;
    width: 100%;
    display: block;
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 10;
}

.navigation ul li {
    list-style: none;
    margin-bottom: 0px;
}

.navigation ul li.bg-auth a {
    background: var(--red);
    background: #E7AB54;
    color: #fff;
    border-radius: 50px;
    padding: 15px 25px;
}

.sec-nav {
    background: #E6DFD0;
    padding: 10px 1.5%;
    display: flex;
    justify-content: space-between;
}

.sec-nav a {
    font-size: 1.4rem;
    color: #000;
    text-decoration: none;
}

.sec-nav p {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.ant-drawer-header {
    display: none !important;
}

.paymentModal .ant-modal-close {
    display: none;
}

.ant-drawer-body {
    padding: 0px !important;
}

/* margin */
.pt_5 {
    padding-top: 5%;
}

.pb_5 {
    padding-bottom: 5%;
}

.pt_3 {
    padding-top: 3%;
}

.pt_4 {
    padding-top: 4%;
}

.mt_5 {
    margin-top: 5%;
}

.mt_4 {
    margin-top: 4%;
}

.mt_3 {
    margin-top: 3%;
}

/* layout */
.btn-array {
    display: flex;
    grid-gap: 10px;
    text-align: center;
}

.btn-flex {
    display: flex;
    grid-gap: 10px;
}


.center_align_message {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40rem;
}

.center_align_message p {
    font-size: 1.6rem;
    width: 60%;
    margin: 0px auto;
    display: block;
}

.center_align_message h3 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

.plan_bg {
    background: #222;
    /* background: url('../../assets/images/mini/dark-pattern.png'); */
    padding: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan_bg h3 {
    font-size: 3rem;
    color: #fff;
}

.page-title {
    font-size: 2rem;
}

.ant-skeleton.ant-skeleton-element {
    width: 100%;
}

.homepage .ant-skeleton.ant-skeleton-element .ant-skeleton-image {
    background: #fff;
}

.homepage .ant-skeleton.ant-skeleton-element .ant-skeleton-input {
    background: #fff;
    /* width: 100%; */
}

.btn-red {
    background: var(--red);
    padding: 13px 25px;
    border: 1px solid var(--red);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

.btn-border-red {
    background: transparent;
    padding: 13px 25px;
    border: 2px solid var(--red);
    border-radius: 4px;
    color: var(--red);
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

.btn-red.full_width,
.btn-white.full_width,
.btn-border-red.full_width {
    width: 100%;
}

.btn-red.curve,
.btn-border-red.curve {
    border-radius: 50px;
}

.btn-white {
    background: #fff;
    padding: 13px 25px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

.btn-black {
    background: var(--black);
    padding: 13px 25px;
    border: 1px solid var(--black);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

/* btn_border_black */

.center-text {
    text-align: center;
}

.center-text.share-bar img {
    width: 20%;
    height: auto;
    margin-bottom: 40px;
}

.center-text.share-bar h3 {
    width: 60%;
    margin: 0px auto;
    display: block;
    line-height: 1.4;
    margin-bottom: 10px;
    font-size: 1.8rem;
}

.center-text.share-bar p {
    /* font-size: 1.4rem;
    line-height: 1.8; */
}

.center-text.share-bar .share-icon-box {
    border: 2px solid #002333;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 20px;
}

.center-text.share-bar .share-input-box {
    margin-top: 20px;
    display: flex;
}

.center-text.share-bar .share-input-box button {
    width: 100%;
    height: 100%;
    padding: 10px 35px;
    border-radius: 0px 8px 8px 0px;
}

.center-text.share-bar .share-input-box input {
    height: 6rem;
    border-radius: 8px 0px 0px 8px;
}

.center-text.share-bar .share-icon-box-cover {
    display: flex;
    grid-gap: 15px;
    margin: 0px auto;
    width: max-content;
}

.btn-yellow {
    background: var(--yellow);
    padding: 13px 25px;
    border: 1px solid var(--yellow);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

.btn-green {
    background: var(--green);
    padding: 13px 25px;
    border: 1px solid var(--green);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}
.btn-border-green {
    background: transparent;
    padding: 12px 25px;
    border: 2px solid var(--green);
    border-radius: 4px;
    color: var(--green);
    cursor: pointer;
    display: block;
    width: max-content;
    font-size: 1.4rem;
}

.btn-yellow svg {
    /* stroke: #fff; */
    fill: #fff;
}

.btn-black.curve,
.btn-yellow.curve,
.btn-green.curve {
    border-radius: 50px;
}

.btn-default {
    border: 2px solid #ccc;
    border-radius: 50px;
    padding: 10px 25px;
    background: transparent;
    color: #ccc;
    font-size: 1.4rem;
}

.btn-blank {
    background: transparent;
    padding: 13px 25px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: var(--black);
    cursor: pointer;
    display: block;
    width: max-content;
    text-decoration: underline;
}

.btn-blank-yellow {
    background: transparent;
    border: 3px solid var(--yellow);
    padding: 13px 25px;
    border-radius: 4px;
    color: var(--black);
    cursor: pointer;
    display: block;
    width: max-content;
    text-decoration: underline;
}

.hr-line {
    background: #454545;
    height: 1.5px;
    widows: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.grid-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contain {
    padding: 0px 3%;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.grid-2 img,
.grid-3 img,
.grid-4 img {
    width: 100%;
    height: auto;
}

/* footer */

/* footer */
.footer {
    padding: 4% 0;
    background: #000;
    background: #222222;
    margin-top: 5%;
}

.footer.no-margin {
    margin-top: 0px;
}

.footer .biased_grid_4 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 10px;
}



.footer p,
.footer ul li a,
.footer a {
    color: #cccccc;
    line-height: 2.1;
    font-size: 1.3rem;
    text-decoration: none;
    /* font-family: 'DM Sans', sans-serif; */
}

.footer ul li {
    margin-bottom: 5px;
    list-style: none;
}

.footer h5 {
    color: #fff;
    font-size: 1.4rem;
    /* font-family: 'DM Sans', sans-serif; */
}

.footer h3 {
    font-size: 1.4rem;
    color: #fff;
    /* font-family: 'DM Sans', sans-serif; */
}

.footer ion-icon {
    color: #cccccc;
    font-size: 20px;
    margin-right: 10px;
}

.footer ul li:last-child,
.footer p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.footer.margin {
    margin-top: 5%;
}

.sec_footer {
    margin-top: 5%;
}

.sec_footer button {
    background: var(--red);
    border: 1px solid var(--red);
    border-radius: 3px;
    border-radius: 0 4px 4px 0;
    color: #ccc;
    font-size: 1.2rem;
}

.sec_footer ul {
    margin: 0%;
    padding-left: 0px;
}

.sec_footer ul li {
    display: inline-block;
    padding-right: 10px;
    margin-bottom: 0px;
}

.sec_footer input {
    background: transparent;
    height: 4.5rem;
    color: #cccccc;
    /* font-family: 'DM Sans', sans-serif; */
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #cccccc;
}

.sec_footer form {
    width: 80%;
}

.sec_footer form div {
    display: grid;
    grid-template-columns: 3fr 1.5fr;
}

.sec_footer .grid_flex {
    align-items: center;
}

.sec_footer button {
    background: var(--yellow);
    border: 1px solid var(--yellow);
    color: #fff;
    border-radius: 3px;
    border-radius: 0px 4px 4px 0px;
    font-size: 1.2rem;
}

.sec_footer_block {
    flex: 1;
}

.sec_footer .sec_footer_block div {
    text-align: end;
}

.sec_footer .sec_footer_block ul li:last-child {
    padding-right: 0px;
}

.footer .footer-grid-title ion-icon {
    display: none;
}

.footer .compartment ul {
    margin: 0px;
    padding: 0px;
}

/* form */
.width_70 {
    width: 70%;
}

.form_flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.errorMessage {
    font-size: 1.3rem;
    color: #E83F36;
}

label {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.form-flex-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.form-group {
    margin-bottom: 20px;
}

/* pheripheral */
.ant-drawer-header {
    display: none;
}

.bg-danger {
    background: var(--red);
    padding: 4px 15px;
    font-size: 1.3rem;
    border-radius: 3px;
    border: 1px solid var(--red);
    color: #fff;
}



.bg-primary {
    background: #005CE6;
    padding: 4px 15px;
    font-size: 1.3rem;
    border-radius: 3px;
    border: 1px solid #005CE6;
    color: #fff;
}

.ant-table-tbody p {
    margin-bottom: 0px;
}

.main-video-display {
    /* background: #F4F4F4; */
}

.main-video-display .main-video-content-display {
    /* display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 50px;
    min-height: 40rem; */
}

.main-video-display .main-video-content-list ul {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}

.main-video-display .main-video-content-list ul li {
    font-size: 1.4rem;
    list-style: none;
}

.main-video-display .main-video-content-list ul li a {
    text-decoration: none;
    color: #394649;
}

.main-video-content-list ul li.active-bar {
    background: #6a6f73;
    /* margin-left: -3%; */
    padding: 10px;
    border-radius: 3px;
}

.side-filter .extensive-grid-filter .main-video-content-list ul li.active-bar a {
    color: #fff;
}

.main-video-content-list ul li.active-bar a {
    color: #fff;
    border-radius: 4px;
}

.main-video-content .main-content-banner {
    /* height: 25rem; */
    /* width: 100%;
    background: #222;
    border-radius: 8px; */
    margin-bottom: 30px;
}

.main-video-content .main-content-banner img {
    width: 100%;
    border-radius: 4px;
    height: 25rem;
    object-fit: cover;
    object-position: center;
}

.main-video-display .banner-tab {
    display: flex;
    grid-gap: 20px;
    row-gap: 10px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.main-video-display .banner-tab button,
.tag-block button {
    padding: 6px 25px;
    background: transparent;
    border: 1px solid #222;
    color: #222;
    border-radius: 4px;
    font-size: 1.3rem;
}

.ant-drawer-body ul {
    margin: 0px;
    padding-left: 0px;
}

@media screen and (max-width: 720px) {
    video {
        width: 100%;
        height: 100%;
    }

    .contain {
        padding: 0px 2%;
    }

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    .auth-display .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .auth-image-bg {
        height: unset;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .nav-drawer .ant-drawer-body ul li a {
        color: #eff0f6;
        color: #111;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Gilroy Regular';
    }

    .nav-arrow {
        width: 6%;
        height: auto;
    }

    .nav-drawer ul li {
        border-bottom: 0.1px solid #b3b3b3;
        line-height: 2.2;
        list-style: none;
        margin-bottom: 15px;
        padding-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
    }

    .nav-drawer ul li a {
        text-decoration: none;
    }

    .nav-drawer .ant-drawer-body {
        background: #000;
        background: #F5FCFF;
        background: #FAF9F7;
    }

    .drawer-nav-block {
        align-items: center;
        border-bottom: 0.1px solid #b3b3b3;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
    }

    .nav-drawer ul li a {
        font-family: 'Graphik';
    }

    /* margin */
    .mt_5 {
        margin-top: 15%;
    }

    .mt_3 {
        margin-top: 9%;
    }

    /* footer */
    .footer .sec_footer .grid-flex {
        display: flex;
        flex-direction: column;
        margin-top: 5%;
    }

    .sec_footer .grid-flex .sec_footer_block {
        width: 100%;
    }

    .sec_footer .sec_footer_block div {
        text-align: left;
    }

    .footer.margin {
        margin-top: 15%;
    }

    .footer {
        padding: 15% 0px;
    }

    .footer-sec.footer .footer-grid .biased_grid_4 {
        display: flex;
        grid-gap: 30px;
        grid-gap: 0px;
        flex-direction: column;
    }

    .footer-sec.footer .footer-grid .biased_grid_4 .grid-2 {
        margin-bottom: 30px;
    }

    .footer-sec.footer .footer-grid .biased_grid_4 .compartment {
        margin-bottom: 30px;
    }

    .footer-sec.footer .footer-grid .biased_grid_4 .grid-2 .compartment {
        margin-bottom: 0px;
    }

    .footer-sec.footer .footer-grid .biased_grid_4 .footer-grid-title {
        margin-bottom: 10px;
    }

    .footer {
        margin-top: 15%;
    }

    .navigation .logo img {
        height: 45px;
        width: 90px;
    }

    .sec_footer form {
        width: 100%;
    }

    .sec_footer {
        margin-top: 0px;
    }

    .ant-modal {
        width: 100% !important;
    }
}

/* .slick-slide {
    margin: 0 27px;
} */
/* 
.slick-list {
    margin: 0 -27px;
} */

.slick-slide {
    margin-right: 20px;
}

.new-swiper-box {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
}

.carousel-controller {
    background: transparent;
    border: none;
}

.carousel-controller svg {
    height: 2rem;
    width: 100%;
}

.carousel-cover {
    position: relative;
}

.swiper {
    position: unset !important;
}