/* .plan_bg {
    background: url('../../assets/images/mini/dark-pattern.png');
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.plan_bg h3 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 0%;
}

.merch_price {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.merch_price span {
    font-size: 1.2rem;
}

.merch_name {
    font-size: 1.4rem;
    margin-bottom: 0%;
    /* font-family: 'Ambit'; */
}

.grid-4 img.merch_image {
    height: 20rem;
    align-items: center;
    width: auto;
}

.grid-4 button.btn_red {
    padding: 5px 25px;
    cursor: pointer;
}

.grid-4 .merch_image_cover {
    background: #F8F7FC;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    overflow: hidden;
    margin-bottom: 5px;
}

.merch-price-grid {
    display: flex;
    grid-gap: 10px;
}

.merch_price.strikethrough {
    text-decoration: line-through;
}

.cart-grid-3 {
    display: grid;
    grid-template-columns: 2fr 5fr 2fr;
    /* align-items: center; */
    grid-gap: 10px;
}

.cart-grid-3 img {
    height: 5rem;
    align-items: center;
    width: auto;
}

.cart-image-cover {
    background: #F8F7FC;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    overflow: hidden;
    margin-bottom: 5px;
}

/* .ant-drawer-body {
    position: relative;
    padding: 10px 10px;
} */

.ant-drawer-body .cart-grid-3 h3 {
    margin-bottom: 0%;
    font-size: 1.4rem;
}

.ant-drawer-body .cart-grid-3 p {
    margin-bottom: 0%;
}

.cart-grid-3 .ant-select-selector {
    height: 4rem !important;
}

.ant-drawer-body ion-icon {
    font-size: 1.8rem;
    color: var(--red);
    cursor: pointer;
}

.ant-drawer-body a.btn_red {
    position: absolute;
    bottom: 10px;
    width: 95%;
    padding: 12px;
    text-align: center;
}

.checkout-form-cover {
    width: 100%;
    min-height: 40rem;
}

.checkout-form {
    width: 40%;
    margin: 0% auto;
    display: block;
    padding: 5% 0px;
    padding-bottom: 3%;
}

.radioDisplay .ant-space-item {
    border: 1px solid #e5edec;
    border-radius: 3px;
    padding: 10px 5px;
}

.ant-space {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.checkout-confirmation .grid-2-bias {
    grid-gap: 50px;
    display: grid;
    grid-template-columns: 70fr 30fr;
}

.checkout-confirmation .cart-brief {
    position: -webkit-sticky;
    position: sticky;
    top: 15%;
}


.checkout-confirmation .cart-brief-data {
    background: #fff;
    border: 1px solid #e1e3e4;
    border-radius: 3px;
    max-height: 30rem;
    overflow: hidden;
    overflow-x: hidden;
    padding: 3%;
    width: 100%;
}

.checkout-confirmation .cart-brief-data ul li {
    display: flex;
    font-size: 1.5rem;
    justify-content: space-between;
    line-height: 1.5;
    padding: 2px 0;
}

.currency {
    font-size: 1.2rem;
}

.ant-space-item h6 {
    font-size: 1.5rem;
}

.ant-skeleton .ant-skeleton-image {
    width: 100% !important;
    height: 18rem !important;
}

.video-skeleton .ant-skeleton {
    width: 100% !important;
    margin-bottom: 20px;
}

@media screen and (max-width: 720px) {
    .shop .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-4 img.merch_image {
        /* width: 100%; */
        height: 15rem;
    }

    .merch_name {
        /* font-family: 'Founders Grotesk Medium'; */
        font-size: 1.4rem;
    }

    .merch_price {
        font-size: 1.2rem;
    }

    .grid-4 button.btn_red {
        padding: 7px 15px;
        margin-bottom: 0%;
        font-size: 1.2rem;
    }

    .shop .grid-4 {
        grid-gap: 10px;
        row-gap: 40px;
    }
}