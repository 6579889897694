.main_info.profile_div {
    /* min-height: 80vh;
    min-height: 500px; */
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.main_info.profile_div .profile_data {
    height: 100%;
}


.profile_to_left {
    /* background: #F7EEE3; */
    /* position: fixed; */
    height: 100%;
    width: 100%;
    padding-top: 15%;
    padding-left: 7%;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.profile_to_left .fix-nav {
    position: sticky;
    top: 5%;
}

.profile_to_left ul {
    padding-left: 30px;
    margin-bottom: 0%;
}

.profile_to_left ul li {
    list-style: none;
    line-height: 2.4;
}

.profile_to_left ul li a {
    color: #111;
    font-size: 1rem;
    font-weight: 500;
}

.profile_to_left .profile_nav {
    height: 100%;
}


/* .profile_nav ul {
    margin-left: 20px;
} */

.profile_div .profile_to_right .profile-data-display {
    padding: 5% 0px;
    padding-bottom: 0px;
}

.profile_nav ul li {
    list-style: none;
    padding-bottom: 10px;
    line-height: 1.9;
}

.profile_nav ul h4,
.profile_nav ul li a {
    color: #111;
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
}

.profile_nav ul h4 {
    line-height: 1;
    font-size: 1.6rem;
}

.profile_nav ul li a ion-icon,
.profile_nav ul h4 ion-icon {
    font-size: 1.8rem;
    margin-right: 10px;
}

.profile_title {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 2%;
    margin: 0px;
}

.profile_nav ul li:last-child {
    margin-bottom: 0%;
}

.empty_div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 25rem;
}

.empty_div img {
    width: 60%;
    height: auto;
    display: block;
    margin: 0% auto;
}

.empty_div p {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.empty_div h3 {
    font-size: 1.6rem;
}

.empty_div a {
    margin: 0px auto;
    display: block;
    text-decoration: unset;
    font-size: 1.4rem;
}

.categoryDisplay {
    display: flex;
    margin-bottom: 30px;
    grid-gap: 20px;
}

.tag {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 8px 15px;
    margin: 0%;
    font-size: 1.3rem;
    border-radius: 3px;
    cursor: pointer;
}

.tag.active {
    background: var(--red);
    border-color: var(--red);
    color: #fff;
}

.inline_video_flex {
    display: flex;
    grid-gap: 10px;
    /* display: none; */
}

.inline_video_flex p {
    background: var(--black);
    color: #fff !important;
    font-size: 1rem !important;
    padding: 8px 15px;
    margin-bottom: 0% !important;
    border-radius: 3px;
}


.training_group {
    display: flex;
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    grid-gap: 20px;
}

.training_inside_group p,
.training_inside_group ul {
    margin-bottom: 0px;
}


/* training_inside_group */
.training_inside_group {
    display: flex;
    align-items: baseline;
    align-items: center;
    grid-gap: 20px;
}

.training_inside_group img {
    width: 100%;
    height: 100%;
    /* width: 106px;
    height: auto; */
    margin-bottom: 0px;
}

.training_inside_img {
    flex: 1;
}

.training_inside_div {
    flex: 6;
}

.training_group {
    display: flex;
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    grid-gap: 20px;
}

.training_inside_group p,
.training_inside_group ul {
    margin-bottom: 0px;
}

.training_side_cover {
    display: flex;
    grid-gap: 20px;
    align-items: center;
}

.plans .grid_3_bias .plan_grid_main_detail h3.physical_plan_title {
    font-size: 1.5rem;
}


/* plan_title */
.plan_title {
    font-size: 2rem;
    margin-bottom: 5px;
    /* font-weight: 700; */
}

.plan_desc {
    font-size: 1.7rem;
    line-height: 1.5;
}

.plans .grid_3_bias .plan_grid_main .plan_grid_main_detail ul.physical_plans_list li,
ul.physical_plans_list li {
    display: inline-block;
    padding-right: 10px;
    font-size: 1.4rem;
    color: rgb(0 0 0 / 60%);
}

.video-poster {
    position: relative;
}

.plan_video_display a {
    text-decoration: unset;
}

.plan_video_display .grid-4 img {
    height: 12rem;
    border-radius: 4px 4px 0px 0px;
}

.videos-specific-page .plan_video_display .grid-4 img {
    height: 16rem;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}

.videos-specific-page .plan_video_display ul li {
    font-size: 1.3rem;
    color: #000;
    line-height: 1.8;
}

.videos-specific-page .product-plan-img {
    position: relative;
}

.videos-specific-page .product-plan-img .product-plan-bg {
    position: absolute;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.other-dance-styles.plan_video_display .grid-4 img {
    height: 16rem;
}

.plan_video_display .grid-4 .video-poster h4 {
    bottom: 10px;
    width: 100%;
    margin-bottom: 0px;
    border-radius: 0px 0px 10px 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 1.4rem;
    line-height: 1.3;
    color: #111;
    text-decoration: unset;
    border-bottom: unset;
}

.other-dance-styles.plan_video_display .grid-4 .video-poster h4 {
    font-size: 1.4rem;
}

.other_profile_title {
    font-size: 1.8rem;
}

.categoryDisplay {
    flex-wrap: wrap;
}

.mobile-only .mt-5 {
    margin-top: 0px;
}

.mobile-only .mt-5 .ant-collapse-header .ant-collapse-expand-icon {
    display: none;
}

.mobile-only .mt-5 .ant-collapse-header {
    padding: 10px;
}

.mobile-only .mt-5 .ant-collapse-content .ant-collapse-content-box ul.transactionHistory li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 1.2rem;
    align-items: center;
}

.ant-collapse.ant-collapse-icon-position-start {
    margin-bottom: 20px;
}

.mobile-only .mt-5 .ant-collapse-header .ant-collapse-header-text {
    font-size: 1.5rem;
}

.mobile-only .mt-5 .ant-collapse-content .ant-collapse-content-box {
    padding: 10px 10px;
}

.mobile-only .mt-5 .ant-collapse-content .ant-collapse-content-box ul.transactionHistory li:last-child {
    margin-bottom: 0px;
}

.mobile-only .mt-5 .ant-collapse-content .ant-collapse-content-box ul.transactionHistory li span:last-child {
    text-align: right;
}

.product-display.empty_div {
    min-height: unset;
}

.product-display.empty_div img {
    height: auto;
    width: 30%;
}

.product-display.empty_div p {
    margin-bottom: 0px;
}

.profile_div.main_info .profile_to_right h3.other-prroducts-title {
    font-size: 1.8rem;
}

.profile_div.main_info .profile_to_right .empty-product-display img {
    height: 25rem;
}

.profile_div.main_info .profile_to_right .empty-product-display p {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 0px;
}

.profile_div.main_info .profile_to_right .empty-product-display a {
    font-size: 1.4rem;
}

.profile_div.main_info p.merch_name {
    font-family: inherit;
}

.flex-btn span {
    display: flex;
    align-items: center;
    grid-gap: 5px;
}

.unimportant,
.training_inside_group p.unimportant {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

@media screen and (max-width: 720px) {

    .training_group,
    .training_inside_group {
        display: block;
    }

    .plan_title,
    .plans .grid_3_bias .plan_grid_main_detail h3.physical_plan_title {
        font-size: 1.5rem;
        line-height: 1.2;
        color: var(--red);
        margin-bottom: 0px;
    }

    .training_group .training_inside_group {
        display: grid;
        grid-template-columns: 1fr 4fr;
    }

    .training_inside_group {
        display: flex;
        display: grid;
        grid-template-columns: 1.2fr 4fr;
        grid-template-columns: 4fr 1.2fr;
    }

    .training_group,
    .training_inside_group {
        display: block;
    }

    .training_group .training_inside_group {
        display: grid;
        grid-template-columns: 1fr 4fr;
    }

    .training_inside_group .training_inside_img {
        /* width: 20%; */
        margin-bottom: 0px;
    }

    .training_inside_group .unimportant {
        display: block;
    }

    .training_inside_group.sec-group p {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
    }

    .profile_div.main_info {
        display: block;
    }

    .profile_div.main_info .profile_to_left {
        display: none;
    }

    .profile_div.main_info .width_70 {
        width: 100%;
    }

    .block-bg {
        background: #111;
        height: 18rem;
        width: 100%;
        border-radius: 3px;
    }

    .profile_div .profile_to_right .profile-data-display {
        padding: 15% 0px;
    }

    .empty_div {
        min-height: 40rem;
    }

    .plan_video_display .grid_3 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 720px) {
    .profile_div .profile_to_right .profile-data-display {
        min-height: 40rem;
    }
}

.video-calendar-block button.btn_red,
.flex-btn.btn_red {
    padding: 6px 15px;
    border-radius: 3px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 20%;
}

.video-calendar-block {
    margin-bottom: 40px;
}

.video-calendar-block form {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.video-calendar-block form button {
    height: 4rem;
    padding: 0px 20px;
    background: var(--red);
    border: none;
    color: #fff;
    font-size: 1.3rem;
    border-radius: 0px 3px 3px 0px;
}

.main_video.plan_group {
    position: relative;
    /* height: 500px; */
    background: #000;
    width: 100%;
    overflow: hidden;
}

.main_video.plan_group video {
    /* position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 10px);
    width: 100vw;
    height: 56.25vw; */
    width: 100%;
    height: 100%;
}

.search-display .search-title {
    font-size: 1.6rem;
    margin-bottom: 0px;
}

.search-display button.btn_border_black {
    padding: 5px 15px;
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.search-display button.btn_border_black img {
    height: 1.6rem;
    width: auto;
}

.search_empty_div {
    width: 20%;
}

.search_empty_div img {
    width: 70%;
    height: auto;
}

.search_empty_div p {
    font-size: 1.3rem;
    /* text-align: center; */
}


.video-calendar {
    width: 720px !important;
}

.video-calendar .ant-modal-body {
    padding: 30px 15px;
}

.video-calendar .ant-pagination.ant-table-pagination {
    display: none;
}

.video-calendar .ant-table-thead>tr>th,
.video-calendar .ant-table-tbody>tr>td,
.video-calendar .ant-table tfoot>tr>th,
.video-calendar .ant-table tfoot>tr>td {
    padding: 10px;
    font-size: 1.3rem;
}

.video-calendar .inner-fragment {
    padding-top: 10px;
}

.video-player-controls {
    display: flex;
    grid-gap: 20px;
}

.video-player-information {
    position: absolute;
    z-index: 100;
    background: #fff;
    bottom: 0px;
    padding: 5px 30px;
    padding-top: 10px;
}

.video-player-information p {
    font-size: 1.4rem;
    color: unset;
    margin-bottom: 0px;
    /* font-family: 'Ambit'; */
}

.video-player-controls button {
    background: transparent;
    border: none;
    cursor: pointer;
}

.video-player-controls button ion-icon {
    font-size: 3rem;
}

.video-calendar-block form button {
    height: 5rem !important;
}

.video-calendar-block form input {
    border-radius: 4px 0px 0px 4px;
}

@media screen and (max-width: 720px) {
    .empty-product-display.physical.plans .grid-4 {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }

    .product-display.empty_div {
        min-height: 30rem;
    }

    .profile_div.main_info .profile_to_right .empty-product-display img {
        height: 20rem;
    }

    .profile-data-display .grid-4.merch_box {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }

    .plan_video_display .grid-4 {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }

    .video-calendar-block .grid_flex {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: unset;
    }

    .video-calendar-block .flex-btn.btn_red {
        width: max-content;
        grid-gap: 10px;
        padding: 10px 25px;
    }

    .video-calendar-block form {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    .video-calendar-block form button,
    .video-calendar-block form input {
        height: 5rem !important;
    }

    .detail_props.trending_videos .contain {
        padding: 0% 2%;
    }

    .other-dance-styles.plan_video_display .grid-4 img {
        height: 12rem;
    }

    .other_profile_title {
        margin-bottom: 0px;
    }

    .other-dance-styles.plan_video_display .grid-4 .video-poster h4 {
        font-size: 1.2rem;
    }

    .main_video.plan_group {
        height: 550px;
    }

    .main_video.plan_group video {
        /* position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        overflow: hidden; */
    }

    .video-player-information {
        bottom: 0px;
        padding: 5px 2%;
        padding-top: 10px;
    }
}

.main_video_player {
    background: url('../../assets/images/mini/dark-pattern.png');
}

.video-product-tag p {
    border-radius: 30px;
    border: 1px solid rgba(253, 253, 253, 0.6);
    color: #fff;
    padding: 10px 25px;
    font-size: 1.4rem;
    grid-gap: 20px;
    display: flex;
    align-items: center;
}

.video-product-tag {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    grid-gap: 0px;
    column-gap: 10px;
}

.main_video_player h3 {
    color: #fff;
    font-size: 2.3rem;
    padding-top: 40px;
    margin-bottom: 0px;
}

.video-product-tag svg {
    font-size: 1.5rem;
}

.main_video_player .contain {
    padding: 0px 1.5rem;
}

@media screen and (max-width: 720px) {
    .main_video_player .grid_2 {
        display: flex;
        flex-direction: column-reverse;
        /* grid-gap: 20px;
        flex-wrap: wrap;
        grid-gap: 0px;
        column-gap: 10px; */
    }

    .main_video.plan_group {
        height: unset;
    }

    .video-product-tag p {
        font-size: 1rem;
        padding: 10px 15px;
    }

    .video-product-tag {
        padding-bottom: 30px;
    }

    .main_video_player h3 {
        padding-top: 20px;
    }
}

.profile-money-dash {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;
}

.profile-money-dash .profile-money-summary {
    border: 1px solid #E6FAFF;
    padding: 20px 10px;
    border-radius: 4px;
    background: #E6FAFF;
}

.profile-money-dash .profile-money-summary._2 {
    border: 1px solid #F3F6FF;
    background: #F3F6FF;
}

.profile-money-dash .profile-money-summary._3 {
    border: 1px solid #FFF0F4;
    background: #FFF0F4;
}

.profile-money-dash .profile-money-summary._3 {
    border: 1px solid #ECFBEC;
    background: #ECFBEC;
}

.profile-money-dash .profile-money-summary p {
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.profile-money-dash .profile-money-summary h3 {
    /* margin-bottom: 0px; */
    font-size: 2rem;
}